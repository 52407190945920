import React, { useState, useRef, useEffect } from 'react';
import '../styles/styles.css';
import ReactPlayer from 'react-player';

function Works() {
  const videos = [
    {id: 1, title: 'Sunset / CC', url: "https://player.vimeo.com/video/272360696?h=948e54ec41",  image: "images/sunset.jpg"}, 
    {id: 2, title: 'Vuelta a clases / WOM', url: "https://player.vimeo.com/video/429795517?h=2c3bf7b975", image: "images/clases.jpg"},
    {id: 3, title: 'Winter Sun / CORONA', url: "https://player.vimeo.com/video/643683630?h=e8db0ac367", image: "images/winter.jpg"},
    {id: 4, title: 'Museo Interactivo', url: "https://player.vimeo.com/video/644355686?h=4639e6f82f", image: "images/museo.jpg" },
    {id: 5, title: 'Trailer / ANIMAL', url: "https://player.vimeo.com/video/356166114?h=c463678d49", image: "images/animal.jpg"},
    {id: 6, title: 'Para Todos / CC', url: "https://player.vimeo.com/video/337311301?h=756c9dc8fd", image: "images/cc1.jpg"},
    {id: 7, title: 'Real Magic / Coca-Cola', url: "https://player.vimeo.com/video/727156049?h=6adfb8c1db", image: "images/cc2.jpg"},
    {id: 8, title: 'Move On / WOM', url: "https://player.vimeo.com/video/731761774?h=96d4e66aea", image: "images/wom.jpg"},
    {id: 9, title: 'Manifiesto / Entel', url: "https://player.vimeo.com/video/734007867?h=76eea495c5", image: "images/entel.jpg" },
    {id: 10, title: 'Anda mejor / YPF', url: "https://player.vimeo.com/video/147270265?h=350250d880", image: "images/ypf.jpg" },
    {id: 11, title: 'Desafio / BDC', url: "https://player.vimeo.com/video/314112228?h=9a3d626ccf", image: "images/bdc.jpg" },
    {id: 12, title: 'Manifiesto / QUILMES', url: "https://player.vimeo.com/video/190256669?h=965846f795", image: "images/quilmes.jpg" },
    {id: 13, title: 'SafetyTruck / SAMSUNG', url: "https://player.vimeo.com/video/132256547?h=f0a5453070", image: "images/samsung.jpg" },
    {id: 14, title: 'Actitud / L33', url: "https://player.vimeo.com/video/139144436?h=39a7248449", image: "images/actitud.jpg" },
    {id: 15, title: 'Mas / Wom', url: "https://player.vimeo.com/video/786235165?h=c324a4c71e", image:"images/wom2.jpg" },
  ];

  const [hoveredVideoId, setHoveredVideoId] = useState(null);
  const [currentUrl, setCurrentUrl] = useState("");
  const [floatingDivVisible, setFloatingDivVisible] = useState(false);
  const [play, setPlay] = useState(true);
  const playerRef = useRef(null); 
  const divRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        event.target.tagName !== 'VIDEO'
      ) {
        setFloatingDivVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [divRef]);

  const handleVideoClick = (url, event) => {
    event.stopPropagation();
    setCurrentUrl(url);
    setFloatingDivVisible(true);
    setPlay(true);
  };

  const handleFloatingDivClick = () => {
    // Pausing and resetting the React Player
    if (playerRef.current) {

      setPlay(false);
      
      playerRef.current.player.seekTo(0);

    }
    setFloatingDivVisible(false);
  };

  return (
    <>
      <div className='eempty' />
      <div className="video-container">
        <div ref={divRef} className={`floating-div ${floatingDivVisible ? 'visible' : ''}`} onClick={handleFloatingDivClick}>
          <ReactPlayer
            ref={playerRef} 
            url={currentUrl}
            controls={true}
            playing={play}
          />
        </div>
        {videos.map((video) => (
          <div
            className="video-list"
            key={video.id}
            onMouseEnter={() => setHoveredVideoId(video.id)}
            onMouseLeave={() => setHoveredVideoId(null)}
            onClick={(event) => handleVideoClick(video.url, event)}
          >
            <img
              src={video.image}
              alt={video.title}
              className="video-thumbnail"
            />
            <div className="video-overlay" style={{ visibility: hoveredVideoId === video.id ? 'visible' : 'hidden' }}>
              <div className="video-title">{video.title}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Works;
