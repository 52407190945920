import React from 'react';
import '../styles/styles.css';

function Bio() {
  return(
    <div className='Bio'>
      <div className='eempty'/>
      <h2>BIO</h2>
      <section >
        <p>Born & raised in Buenos Aires, Martin E. Piñeiro has as a background studies at the Film University and courses on documentary and scriptwriting with a background as developer of interactive experiences & Animation.</p>
        <p>When he began working he made the Samsung Truck project that won a Titanium Lion at Cannes in 2015. In the following years, he shot a great number of commercials, video clips & short films exploring a variety of genres with a reel that balances narrative, comedy, and a strong cinematic style. He just finished his first film and is aiming to make a second one.<br/></p>
        <p>He has worked as an advertising director filming in different corners of the world (Latin America, USA, Europe & Middle East) and winning international awards (Cannes, NY, London)  for clients such as Coca-Cola, S. C. Johnson, VW, Corona, Moët Hennessy Louis Vuitton, Samsung, Colgate, among many others.</p>
      </section> 
    </div>
  )
}

export default Bio;